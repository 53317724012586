export default {
  infoItems: [
    {
      label: 'Name',
      prop: 'name'
    },
    {
      label: 'IC',
      prop: 'ic'
    },
    {
      label: 'Email',
      prop: 'email'
    },
    {
      label: 'Phone Number',
      prop: 'phone'
    },
    {
      label: 'Role',
      prop: 'role'
    },
    {
      label: 'Institution',
      prop: 'institution_name'
    },
    {
      label: 'Active',
      prop: 'active'
    },
    {
      label: 'MCR',
      prop: 'mcr'
    },
    {
      label: 'SNB',
      prop: 'snb'
    }
  ]
}