<template>
  <div>
    <InfoViewFrame
      :info-items="infoItems"
      :actions="actions" 
      :item="userInfo"
      :cid="id"
      :hasHistory="false"
    />
  </div>
</template>
<script>
import InfoViewFrame from '@/components/InfoViewFrame'
import { getUserInfo } from '@/services/user'
import jsonData  from './jsonData'
export default {
  components: {
    InfoViewFrame
  },
  data () {
    return {
      id: '',
      userInfo: null,
      infoItems: jsonData.infoItems,
      actions: [
        {
          label: 'Edit',
          url: '/users/update',
          type: 'jump'
        }
      ]
    }
  },
  created () {
    this.id = this.$route.query.id
    this.initUserInfo()
  },
  methods: {
   async initUserInfo () {
      try {
        const res = await getUserInfo(this.id)
        if (res.data.user) {
          const { user } = res.data
          if (Object.hasOwn(user, 'institution')) {
            user.institution_name = user.institution.name
          }
          this.userInfo = res.data.user
        }
      } catch (error) {
        
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>